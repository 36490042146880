import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

const StyledSimpleTable = styled.table`
  ${({ theme }) => css`
        border-collapse: collapse;
        border-spacing: ${theme.size.spacing.small.value}
        padding: ${theme.size.spacing.large.value};
        width: 100%;
    `};
`;

const SimpleTable = ({ children, className, ...other }) => (
  <StyledSimpleTable {...{ children, className }} {...other} />
);

SimpleTable.propTypes = {
  /** Allows you to pass semantic HTML `table` elements as children components */
  children: PropTypes.node.isRequired,
  /** Adds additional className prop to override styling */
  className: PropTypes.string,
};

export { SimpleTable, StyledSimpleTable };
