import React from 'react';
import { getHexToRgb } from 'lib/core';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

const StyledSimpleTableHeader = styled.th`
  ${({ textAlign, theme }) => css`
    background-color: ${theme.color.additional.light.value};
    box-shadow: inset 0 -2px 0 rgba(${getHexToRgb(theme.color.additional.dark.value)}, 0.9);
    font-weight: ${theme.typography.table.fontWeight.value};
    font-family: ${theme.typography.table.fontFamily.value};
    padding-bottom: ${theme.size.spacing.medium.value};
    padding-left: ${theme.size.spacing.large.value};
    padding-right: ${theme.size.spacing.large.value};
    z-index: ${theme.zIndex.simpleTableHeader.value};
    text-align: ${textAlign};
  `};
`;

const SimpleTableHeader = ({ children, className, textAlign = 'left', ...other }) => (
  <StyledSimpleTableHeader {...{ children, className, textAlign }} {...other} />
);

SimpleTableHeader.propTypes = {
  /** Allows you to pass semantic HTML table elements, common to `th`, as children components */
  children: PropTypes.node,
  /** Adds additional className prop to override styling */
  className: PropTypes.string,
  /** Aligns text in cells to specified value. Defaults to text-align: left */
  textAlign: PropTypes.string,
};

export { SimpleTableHeader };
