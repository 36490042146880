import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { truncateText, typographySubtitle1 } from './../../core';

const StyledCardTitle = styled.div`
  ${({ theme }) => typographySubtitle1(theme)};
  ${truncateText};
`;

const CardTitle = ({ customTag = undefined, ...other }) => <StyledCardTitle as={customTag} {...other} />;

CardTitle.propTypes = {
  /** Ability to supply a different element instead of the default one */
  customTag: PropTypes.elementType,
};

export { CardTitle };
