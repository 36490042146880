import React, { useEffect, useRef, useState } from 'react';

import { getRem } from 'lib/core';
import { LIST_SIZES } from 'lib/list';
import { Menu, MenuListItem } from 'lib/menu';
import { useOutsideClickEventListener } from 'lib/utilities';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { CHIP_BACKGROUND_APPEARANCES } from '../../constants';
import { Chip } from '../Chip';

const StyledChip = styled(Chip)`
  max-width: ${getRem('256px')};
`;

const SelectChip = ({
  backgroundAppearance = CHIP_BACKGROUND_APPEARANCES.LIGHT,
  dataTestId = undefined,
  defaultLabel = '',
  defaultSelectedValue = undefined,
  icon = undefined,
  isDisabled = undefined,
  onClose = () => {},
  onOpen = () => {},
  onRemove = () => {},
  onSelect = () => {},
  options,
  prefix = '',
  size = LIST_SIZES.STANDARD,
  ...other
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState(defaultSelectedValue);

  const labelRef = useRef();
  const menuContainerRef = useRef();

  // selectedOption value is -1 if option is not selected or cleared
  const selectedOptionIndex = options && options.findIndex((option) => option.value === selectedValue);
  const displayedLabel = (options[selectedOptionIndex] && options[selectedOptionIndex].label) || defaultLabel;
  const chipProps = {
    onRemove: undefined,
    isSelected: false,
    hasDropdown: true,
  };

  const handleOpen = () => {
    setIsOpen(true);
    onOpen();
  };

  const handleClose = () => {
    setIsOpen(false);
    onClose();
  };

  const handleSelect = (option) => {
    onSelect(option.value);
    setSelectedValue(option.value);
    handleClose();
  };

  if (selectedOptionIndex !== -1) {
    chipProps.onRemove = (event) => {
      setSelectedValue(undefined);
      onRemove(event);
      setIsOpen(false);
    };
    chipProps.isSelected = true;
    chipProps.hasDropdown = false;
  }
  const prefixText = `${prefix} `;

  useOutsideClickEventListener(menuContainerRef, () => {
    handleClose();
  });

  useEffect(() => {
    // warn (in dev environment) if the defaultSelectedValue doesn't match any of the provided options
    if (
      process.env.NODE_ENV === 'development' &&
      defaultSelectedValue &&
      !options.some((option) => option.value === defaultSelectedValue)
    ) {
      console.error('ERROR: The current defaultSelectedValue does not match any option values.');
    }
    setSelectedValue(defaultSelectedValue);
  }, [defaultSelectedValue, options]);

  useEffect(() => {
    const setIsOpenFromMenu = (event) => {
      if (event.detail.ref === menuContainerRef) setIsOpen(event.detail.openMenu);
    };
    document.addEventListener('customMenuOpenEvent', setIsOpenFromMenu);
    return () => document.removeEventListener('customMenuOpenEvent', setIsOpenFromMenu);
  }, []);

  return (
    <>
      <StyledChip
        backgroundAppearance={backgroundAppearance}
        dataTestId={dataTestId}
        icon={icon}
        isOpen={isOpen}
        label={`${prefixText}${displayedLabel}`}
        onClick={isOpen ? null : handleOpen}
        isSelected={selectedValue}
        isDisabled={isDisabled}
        ref={labelRef}
        {...chipProps}
      />
      <Menu
        labelRef={labelRef}
        ref={menuContainerRef}
        isAuto={false}
        isOpen={isOpen}
        onOpen={handleOpen}
        onClose={handleClose}
        data-testid={dataTestId ? `${dataTestId}-menu` : undefined}
        position="bottom-start"
        {...other}
      >
        {options.map((option, ind) => (
          <MenuListItem
            graphicContent={option.icon}
            key={ind}
            onClick={() => handleSelect(option)}
            isDisabled={option.isDisabled}
            isSelected={selectedValue === option.value}
            dataTestId={dataTestId ? `${dataTestId}-menu-item-${ind}` : undefined}
          >
            {option.label}
          </MenuListItem>
        ))}
      </Menu>
    </>
  );
};

SelectChip.propTypes = {
  /** @ignore */
  backgroundAppearance: PropTypes.oneOf(Object.values(CHIP_BACKGROUND_APPEARANCES)),
  /** Id value used for testing */
  dataTestId: PropTypes.string,
  /** Chip label displayed when no chip is selected */
  defaultLabel: PropTypes.node,
  /** Value of pre-selected option (should match a value string from options array.  Put with double-quotes {no braces} when using Storybook Controls, like "option-5") */
  defaultSelectedValue: PropTypes.node,
  /** Shows icon inside the chip. Use icon component from the library */
  icon: PropTypes.node,
  /** Value that controls disabled state */
  isDisabled: PropTypes.bool,
  /** Callback that is called when select is being closed */
  onClose: PropTypes.func,
  /** Callback that is called when select is getting opened */
  onOpen: PropTypes.func,
  /** Callback that is called when remove button is clicked */
  onRemove: PropTypes.func,
  /** Callback that is called when an option is being selected */
  onSelect: PropTypes.func,
  /** Array of options */
  options: PropTypes.arrayOf(
    PropTypes.shape({
      /** Option item icon */
      icon: PropTypes.node,
      /** Option item label */
      label: PropTypes.node.isRequired,
      /** Option item value */
      value: PropTypes.node.isRequired,
    })
  ).isRequired,
  /** Prefix for chip label */
  prefix: PropTypes.node,
  /** Options size */
  size: PropTypes.oneOf(Object.values(LIST_SIZES)),
};

export { SelectChip };
