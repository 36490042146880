import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { hideScrollbar, typographyBody1 } from '../../core';

const StyledTabsHolder = styled.div`
  ${hideScrollbar}
  ${({ theme }) => typographyBody1(theme)};
  background-color: inherit;
  height: 100%;
  overflow-y: auto;
`;

const StyledTabsWrapper = styled.div`
  height: 100%;
  position: relative;
`;

const TabsWrapper = ({ children, className, ...other }) => (
  <StyledTabsWrapper className={className} {...other}>
    <StyledTabsHolder className={className ? `${className}-tabs-holder` : undefined}>{children}</StyledTabsHolder>
  </StyledTabsWrapper>
);

TabsWrapper.propTypes = {
  /** Renders any content passed between tags */
  children: PropTypes.node,
  /** Adds additional class to the component */
  className: PropTypes.string,
};

export { TabsWrapper };
