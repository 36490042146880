import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledSimpleTableHead = styled.thead``;

const SimpleTableHead = ({ children, className, ...other }) => (
  <StyledSimpleTableHead {...{ children, className }} {...other} />
);

SimpleTableHead.propTypes = {
  /** Allows you to pass semantic HTML table elements, common to `thead`, as children components */
  children: PropTypes.node.isRequired,
  /** Adds additional className prop to override styling */
  className: PropTypes.string,
};

export { SimpleTableHead };
