import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { truncateText, typographyButton } from '../../core';

export const StyledTabTitle = styled.span`
  ${truncateText};
  ${({ theme }) => typographyButton(theme)};
  ${({ isActive }) =>
    !isActive &&
    css`
      color: ${({ theme }) => theme.color.text.light.secondary.value};
    `};
`;

const TabTitle = ({ customTag = undefined, isActive = false, ...other }) => (
  <StyledTabTitle as={customTag} isActive={isActive} {...other} />
);

TabTitle.propTypes = {
  /** Ability to supply a different element instead of the default one */
  customTag: PropTypes.elementType,
  /** If true shows title as selected */
  isActive: PropTypes.bool,
};

export { TabTitle };
