import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { getRem, typographyBody1, wordBreak } from '../../core';

const StyledPanel = styled.div`
  ${({ theme }) => typographyBody1(theme)};
  ${wordBreak}
  background-color: ${({ theme }) => theme.color.additional.light.value};
  box-sizing: border-box;
  min-height: 100%;
  padding: ${({ theme }) => theme.size.spacing.large.value};
  padding-top: ${({ offsetTop, theme }) => `calc(${getRem(offsetTop)} + ${theme.size.spacing.large.value})`};
`;

const TabPanel = ({ className, id, offsetTop = 0, ...other }) => (
  <StyledPanel {...{ className, id, offsetTop }} {...other} />
);

TabPanel.propTypes = {
  /** Adds additional class to the component */
  className: PropTypes.string,
  /** TabPanel identifier */
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  /** Adds offset to the top of the panel */
  offsetTop: PropTypes.number,
};

export { TabPanel };
