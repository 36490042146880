import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledSimpleTableBody = styled.tbody`
  text-align: left;
`;

const SimpleTableBody = ({ children, className, ...other }) => (
  <StyledSimpleTableBody {...{ children, className }} {...other} />
);

SimpleTableBody.propTypes = {
  /** Allows you to pass semantic HTML table elements, common to `tbody`, as children components */
  children: PropTypes.node.isRequired,
  /** Adds additional className prop to override styling */
  className: PropTypes.string,
};

export { SimpleTableBody, StyledSimpleTableBody };
