import React from 'react';
import { customScrollbar } from 'lib/core';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledTabsGroup = styled.div`
  ${customScrollbar};
  background-color: inherit;
  border-top-left-radius: ${({ theme }) => theme.size.borderRadius.large.value};
  border-top-right-radius: ${({ theme }) => theme.size.borderRadius.large.value};
  box-sizing: border-box;
  display: flex;
  left: 0;
  overflow: auto;
  position: sticky;
  top: 0;
  width: 100%;
  z-index: ${({ theme }) => theme.zIndex.tabsGroup.value};
`;

const TabsGroup = React.forwardRef(({ className, label, ...other }, ref) => (
  <StyledTabsGroup aria-label={label} className={className} {...other} ref={ref} />
));

TabsGroup.propTypes = {
  /** Adds additional class to the component */
  className: PropTypes.string,
  /** Provides a label that describes the purpose of the set of tabs */
  label: PropTypes.node.isRequired,
};

export { TabsGroup };
