import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

const StyledSimpleTableData = styled.td`
  ${({ textAlign, theme }) => css`
    overflow: visible;
    font-family: ${theme.typography.table.fontFamily.value};
    font-weight: ${theme.font.weight.regular.value};
    padding-left: ${theme.size.spacing.large.value};
    padding-right: ${theme.size.spacing.large.value};
    padding-top: ${theme.size.spacing.small.value};
    padding-bottom: ${theme.size.spacing.small.value};
    text-align: ${textAlign};
  `};
`;

const SimpleTableData = ({ children, className, textAlign = 'left', ...other }) => (
  <StyledSimpleTableData {...{ children, className, textAlign }} {...other} />
);

SimpleTableData.propTypes = {
  /** Allows you to pass any kind of content typically found in an HTML `td` element */
  children: PropTypes.node,
  /** Adds additional className prop to override styling */
  className: PropTypes.string,
  /** Prop to set text-align css value */
  textAlign: PropTypes.string,
};

export { SimpleTableData, StyledSimpleTableData };
