import styled, { css } from 'styled-components';

import { msInputStyles, getRem, transitionAnimation, typographyBody1 } from '../../core';
import { INPUT_SIZES, inputStateOutlineStyles } from '../../input';

const textAreaContentLabelBottomPadding = {
  [INPUT_SIZES.LARGE]: getRem('6px'),
  [INPUT_SIZES.SMALL]: getRem('3px'),
  [INPUT_SIZES.STANDARD]: getRem('6px'),
};

const TextAreaContentLabel = styled.label.withConfig({
  shouldForwardProp: (prop) =>
    ![
      'hasError',
      'hideLabel',
      'isDisabled',
      'isFocused',
      'isHighlighted',
      'isReadOnly',
      'isRequired',
      'maxInputHeight',
    ].includes(prop),
})`
  ${({ theme }) => typographyBody1(theme)};
  ${msInputStyles};
  ${transitionAnimation('border, box-shadow')};
  box-sizing: border-box;
  display: flex;
  flex: 1 1 100%;
  flex-direction: column;
  outline: none;
  padding: 0;
  padding-bottom: ${({ size }) => textAreaContentLabelBottomPadding[size]};
  position: relative;
  width: 100%;
  ${inputStateOutlineStyles.default};

  ${({ hasError, isDisabled, isFocused, isHighlighted, isReadOnly, theme }) =>
    css`
      ${!hasError && !isDisabled && isFocused && !isReadOnly && inputStateOutlineStyles.focus}
      ${!hasError && !isDisabled && !isFocused && isHighlighted && !isReadOnly && inputStateOutlineStyles.hover}
        ${hasError && inputStateOutlineStyles.error}
        ${hasError && isFocused && inputStateOutlineStyles.errorAndFocus}
        ${isReadOnly &&
      css`
        background-color: transparent;
        border-color: transparent;
        box-shadow: none;
      `}
        ${isDisabled &&
      css`
        background-color: ${theme.color.state.default.disabled.value};
        border-color: transparent;
      `}
    `}

  &::placeholder {
    color: ${({ theme }) => theme.color.text.light.disabled.value};
  }
`;

export { TextAreaContentLabel };
